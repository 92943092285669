import React from 'react'
import DetailsSidebar from './ServicesSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const EcommerceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

                        <div className="services-details-desc pt-100">
                            <span className="sub-title">Digital Transformation</span>
                            <h3>Digital transformation frameworks</h3>
                            <p>There are several frameworks that can be used to guide digital transformation initiatives, including:</p>

                            <p><strong>McKinsey Digital Capability Model:</strong> This model considers eight capabilities that organizations need to develop in order to successfully transform digitally: customer experience, data and analytics, digital product and platform development, digital operations, digital marketing and sales, digital procurement, digital talent and culture, and digital strategy and governance.</p>
                            
                            <p><strong>The Three-Speed IT Model:</strong> This model considers three types of IT initiatives: keep-the-lights-on initiatives, optimization initiatives, and transformation initiatives. It helps organizations prioritize initiatives based on the level of impact they will have on the business.</p>

                            <p><strong>Digital Maturity Model:</strong> This model assesses an organization's digital maturity across four dimensions: technology, processes, culture, and customer engagement. It helps organizations understand their current level of digital maturity and identify areas for improvement.</p>

                            <p><strong>The Digital Value Chain:</strong> This model considers the different stages of the value chain, including customer engagement, sales and marketing, order fulfillment, and after-sales service, and assesses how each stage can be transformed digitally.</p>

                            <p><strong>Lean Digital Transformation:</strong> This model is based on the principles of Lean manufacturing and focuses on reducing waste and maximizing value in digital initiatives. It considers five key principles: customer focus, data-driven decision making, continuous improvement, collaboration, and iterative development.</p>
                           
                            <p>These frameworks can be used to guide digital transformation initiatives and ensure that they are aligned with the overall business strategy. By using a structured approach, organizations can prioritize initiatives, track progress, and measure the impact of their digital transformation efforts.</p>

                            {/* <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Offer </h3>
                                        <ul>
                                            <li>UI/UX Design</li>
                                            <li>Modular Content Management</li>
                                            <li>Front-End Development</li>
                                            <li>Back-end Development</li>
                                            <li>DevOps</li>
                                            <li>Analytics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            {/* <h3>E-Commerce Solutions</h3> */}

                            {/* <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Headless eCommerce
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Adobe Magento 
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Shopify eCommerce
                                    </div>
                                </div>
            
                            </div> */}

                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <DetailsSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcommerceDetailsContent